import { HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest, HttpResponse, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { inject, LOCALE_ID, Provider } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Webapps } from '@bs/models/cms/webapps';
import { AppSettings } from '@bs/models/common/app-settings';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { AppSettingsService } from '@bs/services/services/core/app-settings.service';
import { AuthService } from '@bs/services/services/core/auth.service';
import { GeoIpService } from '@bs/services/services/core/geoIp.service';
import { WINDOW } from '@bs/universal/window.service';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

/**
 * class for setting interceptor and setting required headers
 */
export function configFactory<T>(req: HttpRequest<T>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {

  const appSettingsService = inject(AppSettingsService);
  const config = inject(EnvironmentConfig);
  const window = inject<Window>(WINDOW);
  const authService = inject(AuthService);
  const route = inject(ActivatedRoute);

  /**
   * hash {string}
   */
  let hash: string;
  /**
   * isMobile {boolean} sets mobile viewport
   */
  const isMobile: boolean = window.isMobile;
  /**
   * settings variable
   */
  let settings: AppSettings;


  // constructor(private ,  private route: ActivatedRoute) {

  appSettingsService.appSettings$.subscribe({
    next: (response) => settings = response.settings
  });

  // if (window.navigator) {
  //   const fpPromise = FingerprintJS.load();
  //   fpPromise.then(fp => fp.get()).then(result => {
  //     hash = result.visitorId;
  //     appSettingsService.saveSetting('xDeviceId', result.visitorId);
  //   });
  // }


  if (!settings.timeZone) {
    let timeZone;
    if (Intl.DateTimeFormat) {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      //console.log(timeZone)
    } else {
      // Fallback al fuso orario predefinito del sistema o UTC
      if (typeof Intl === 'undefined') {
        // Fallback a UTC
        timeZone = config.timeZone;
      } else {
        // Fallback al fuso orario predefinito del sistema
        timeZone = config.timeZone;
      }
    }
    appSettingsService.saveSetting('timeZone', timeZone);
  }

  //}

  /**
   * this intercept is responsible for default headers setup, and for the user authentication functionalities
   * @param req
   * @param next
   */
    //intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {

  const headers: Record<string, string> = {
    'Accept-Language': settings.languageCode || config.defaultLangCode,
  };

  req.headers.keys().forEach((k) => {
    headers[k] = req.headers.get(k);
  });

  if (isMobile) {
    headers['Mobile'] = '1';
  }

  if (settings.timeZone) {
    headers['X-Time-Zone'] = settings.timeZone;
  }

  if (hash) {
    headers['X-Device-ID'] = hash;
  }

  if (settings?.displayCurrency) {
    headers['DisplayCurrencyId'] = `${settings.displayCurrency.id}`;
  }

  const sid = authService.token;

  if (sid && !req.url.includes('strapi-')) {
    headers['Authorization'] = `Bearer ${sid}`;
  }

  if (!config.production) {
    // debug other on staging
    route.queryParamMap.subscribe({
      next: paramMap => {
        if (paramMap.has('bid')) {
          config.bookmakerId = +paramMap.get('bid');
        }
      }
    });
  }

  if (Webapps.winchester && req.body && typeof req.body === 'object') {
    if ('bookmakerId' in req.body) {
      config.bookmakerId = Number(req.body['bookmakerId']);
    }
  }

  if (req.headers.has('bookmakerId')) {
    config.bookmakerId = +req.headers.get('bookmakerId');
  }

  headers['BookmakerId'] = `${config.bookmakerId}`;
  headers['WebAppId'] = `${config.webAppId}`;
  headers['X-Referer'] =  window?.location.href;

  return next(req.clone({ setHeaders: headers })).pipe(
    catchError(err => {
      switch (err.status) {
        case 401:
          authService.logout('forced', 'not-authorized');
          break;
        case 422:
          // if string contains operation not allowed
          if (err.error.message.startsWith('[Operation not allowed')) {
            authService.logout('forced', 'not-authorized');
          }
          break;
        case 500:
          authService.logout('forced', 'not-authorized');
          appSettingsService.globalErrors$.next({ key: err.status, value: err.error });
          break;
      }
      return throwError(() => ({
        status: {
          code: err.status,
          text: err.statusText
        },
        message: err.error.message,
        details: err.error.details,
        url: err.url
      }));
    }),
    filter((event: HttpEvent<T>) => event.type === HttpEventType.Response),
    map((r: HttpResponse<T>) => {
      /* const responseBody = r.body as { data: T };
       return r.clone({body: responseBody.data});*/
      if (r.body && typeof r.body === 'object' && 'data' in r.body) {
        const responseBody = r.body as { data: T };
        return r.clone({ body: responseBody.data });
      } else {
        return r;
      }
    })
    //map((r: HttpResponse<T>) => r.body && 'data' in r.body ? r.clone({body: (r.body as any).data}) : r)
  );

}

export const coreProviders = (env: EnvironmentConfig) => {

  const optionalProviders: Array<Provider> = [];
  if (env.features.geolocation) {
    optionalProviders.push(GeoIpService);
  }

  return [
    ...optionalProviders,
//    CurrencyPipe,
    provideHttpClient(withInterceptors([configFactory]), withFetch()),
    {
      provide: LOCALE_ID,
      useValue: 'en-GB'
    },
    {
      provide: EnvironmentConfig,
      useValue: env
    }
  ];
};
